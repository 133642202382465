const blogItems = [
    {
        id: 0,
        title: "Chapter 1",
        text: <>When I returned to Goražde from Czechoslovakia in mid-March 1992 with my pregnant wife and son, people told me I was crazy and looked at me as if I had returned from “another” world. The decision was difficult, but our loved ones were in Goražde and we wanted to be with them. Of course, we couldn’t have imagined what kind of evil was coming our way.

We did not react to the first grenades falling around Foča, as if we thought that something similar could not happen to Goražde. However, we had to organize ourselves: we created units and lines of defense in the form of lookouts inside of our buildings. I had a kitchen knife and a small axe.

At the end of March, refugees from Foča, Čajniče, Rogatica and Rudo began to arrive in Goražde.<br/><br/>

Close to 80,000 unarmed people were in the city looking for safety. In the meantime, it became clear that most of our Orthodox neighbors had left their homes and that, by order of their national political leadership, they had weapons and were deployed to war units.

I asked an Orthodox neighbor where his son was. He replied that he went up to Površnica to join the army, and that he was just a cook. At this moment, it became clear to me that the war would start soon. My neighbor’s son came to our building in a war uniform a few days later. Instead of donning a spoon and apron, he had a rocket-propelled grenade, a hand grenade, a pistol and a Kalashnikov.

<br/><br/>
 He told me: “Look, Fule, there will be a little shooting for three or four days and then everything will be over. There is no reason to be afraid. Stay at home. ” After he left, he tried out his grenade at a neighbor’s house and destroyed his roof.

I asked myself – how are we going to defend ourselves?! He had an arsenal of weapons, and I only had a kitchen knife.

The attack on the city began on May 4, 1992, at around 7:30 am. Pride, resistance and the instinct to save our lives woke up. We defended civilians on the lines, and by the banks of the Drina, we identified corpses carried every day by the river from Foča and the surrounding villages. The world calmly observed all this through the eyes of equally calm UN troops stationed in Goražde.

What kind of people committed such atrocities? I never came up with an answer that would at least slightly reduce the tragic consequences of their actions. My mind still cannot accept and understand it.</>
,
compressed: `When I returned to Goražde from Czechoslovakia in mid-March 1992 with my pregnant wife and son, people told me I was crazy and looked at me as if I had returned from “another” world. The decision was difficult, but our loved ones were in Goražde and we wanted to be with them. Of course, we couldn’t have imagined what kind of evil was coming our way.

We did not react to the first grenades falling around Foča, as if we thought that something similar could not happen to Goražde. However, we had to organize ourselves: we created units and lines of defense in the form of lookouts inside of our buildings. I had a kitchen knife and a small axe.

At the end of March, refugees from Foča, Čajniče, Rogatica and Rudo began to arrive in Goražde...`    
}
   
];
export default blogItems;