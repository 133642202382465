import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import NewFooter from "../components/NewFooter";


export default function Documentary() {
    return (
        <>
            <div className="main h-screen">
            <Navbar></Navbar>
                <div className="p-10 md:p-40">
                    <div className="relative" style={{ paddingTop: "42.25%" }}>
                        <iframe className="absolute inset-0 w-full h-full" src="https://www.youtube.com/embed/E2nqftFv6kw" frameborder="0"></iframe>
                    </div>
                </div>
                <NewFooter stickToBottom={true}/>
            </div>
        </>
    );
}

