import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5"

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <div className="md:bg-gray-900 opacity-50  w-screen ">
                <ul className="hidden justify-around md:flex">
                    <NavbarItem text="Home" link="/"></NavbarItem>
                    <NavbarItem text="Documentary" link="/documentary"></NavbarItem>
                    <NavbarItem text="Book" link="/book"></NavbarItem>
                    <NavbarItem text="Gallery" link="/gallery"></NavbarItem>
                    <NavbarItem text="Blog" link="/blog"></NavbarItem>
                    <NavbarItem text="About" link="/about"></NavbarItem>
                    <NavbarItem text="Contact" link="/contact"></NavbarItem>
                </ul>
                <div className={`md:hidden flex flex-col items-end ${ isOpen ? "hidden" : "visible" }`}>
                    <FiMenu className="h-10 w-10 m-5 text-white" onClick={() => { setIsOpen(true) }}></FiMenu>
                </div>
            </div>
            {isOpen &&
                <div className="fixed top-0 left-0 h-full w-full flex flex-col bg justify-around items-center backdrop-blur bg-black/30 z-50">
                    <IoClose className="h-10 w-10 self-end mr-5 text-white"
                    onClick={() => { setIsOpen(false)}}
                    ></IoClose>
                    <MobileNavbarItem text="Home" link="/"></MobileNavbarItem>
                    <MobileNavbarItem text="Documentary" link="/documentary"></MobileNavbarItem>
                    <MobileNavbarItem text="Book" link="/book"></MobileNavbarItem>
                    <MobileNavbarItem text="Gallery" link="/gallery"></MobileNavbarItem>
                    <MobileNavbarItem text="Blog" link="/blog"></MobileNavbarItem>
                    <MobileNavbarItem text="About" link="/about"></MobileNavbarItem>
                    <MobileNavbarItem text="Contact" link="/contact"></MobileNavbarItem>
                </div>
            }
        </>
    );
}


const NavbarItem = ({ text, link }) => {
    return (
        <li>
            <Link to={link}>
                <div className="font-space-mono text-white text-lg mt-5 mb-5">
                    {text}
                </div>
            </Link>
        </li>
    );
}

const MobileNavbarItem = ({ text, link }) => {
    return (
        <Link to={link}>
            <div className="font-space-mono text-white text-3xl mt-5 mb-5">
                {text}
            </div>
        </Link>
    );
}