import React from "react";
import BlogItem from "../components/BlogItem";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import NewFooter from "../components/NewFooter";
import blogItems from "./BlogStore";

export default function Blog() {
    return (
        <>
            <div className="main">
                <Navbar></Navbar>
                <div className="pb-1 sm:p-10">
                    <div className="bg-black bg-opacity-70 p-10 sm:p-20 m-5 sm:m-20 md:40">
                        <div className="text-white font-space-mono text-xl sm:text-3xl mb-10 text-center">FUAD FULE BAVČIĆ’s DIARY</div>
                        <p className="text-white font-space-mono text-justify">
                        Fuad Bavčić is a retired economist and war veteran who spent the war years in the besieged city of Goražde before joining his family in Sarajevo in 1996. In this blog section, you can read Fule’s story as the main protagonist of the film, telling of how his family survived the war.
                        </p>
                        {
                            blogItems.map((item) => {
                                return <BlogItem  id={item.id} title={item.title} text={item.compressed}></BlogItem>;
                            })
                        }
                    </div>
                </div>
                <NewFooter/>
            </div>
        </>
    );
}