import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import NewFooter from "../components/NewFooter";


//const items = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map( (element) => { return `/gallery/${element}.jpg` } )

const items = [
    {
        url: "/gallery/0.webp",
        caption: "Abduselam Pelam Sijerčić’s rifle. He’s a former commander of Bosnian army. Since war ended, he always keeps his rifle close."
    },
    {
        url: "/gallery/1.webp",
        caption: "Deep trenches still painfully scar the landscape on Mount Klek, by the Borovac front line."
    },
    {
        url: "/gallery/2.webp",
        caption: "Deep trenches still painfully scar the landscape on Mount Klek, by the Borovac front line."
    },
    {
        url: "/gallery/3.jpg",
        caption: "Fuad Bavčić, Meša Mutapčić and Mirsad Karović at the top of the Borovac frontline."
    },
    {
        url: "/gallery/4.webp",
        caption: "For three and a half years Goražde was under siege. Bosnian Serb forces pushed the front line as far as the entrance to the city. Many buildings still carry traces of war."
    },
    {
        url: "/gallery/5.webp",
        caption: "Fuad Fule Bavčić, a retired economist and war veteran. He now lives in Sarajevo but often comes back to Goražde."
    },
    {
        url: "/gallery/6.webp",
        caption: "“In Goražde Serbs, Muslims and Croats lived together. When Tito died,everything suddenly changed. We killed each other for no reason”, said Mirsad Romi Karović, a former scout for the Bosnian Army."
    },
    {
        url: "/gallery/7.webp",
        caption: "Rabija Adilović, a peasant woman in the village of Zorovići located between Goražde and Grebak.Thousands of refugees passed by this village on their way to safety."
    },
    {
        url: "/gallery/8.webp",
        caption: "Snow melting and safran flowers, on the Borovac front line, near Goražde."
    },
    {
        url: "/gallery/9.webp",
        caption: "Šehid’s portrait at Semka Bezdrob’s house. Her son Nusret was killed by shrapnel on September 3, 1992."
    },
    {
        url: "/gallery/10.webp",
        caption: "The city of Goražde is situated on the banks of the Drina river in Eastern Bosnia."
    },
    {
        url: "/gallery/11.webp",
        caption: "Trail way to Grebak, a Bosnian outpost west of the Goražde pocket. There, food supplies and ammunition would arrive from Trnovo."
    },
];

export default function Gallery() {
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <div className="main">
                <Navbar></Navbar>
                <div className="pb-1 sm:p-10">
                    <div className="bg-black bg-opacity-70 p-10 sm:p-20 m-5 sm:m-20 md:m-40">
                        <div className="container mx-auto space-y-2 grid sm:grid-cols-2 md:grid-cols-2 lg:space-y-0 lg:gap-3 lg:grid lg:grid-cols-4">
                            {
                                items.map((item, index) => {
                                    return <div className="h-40 w-64 overflow-hidden mx-auto">
                                        <img src={item.url}
                                            onClick={() => {
                                                setPhotoIndex(index);
                                                setIsOpen(true);
                                            }}
                                            alt="item"
                                            className="object-cover"></img>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <NewFooter/>
            </div>
            {isOpen &&
                <Lightbox
                    mainSrc={items[photoIndex].url}
                    nextSrc={items[(photoIndex + 1) % items.length].url}
                    prevSrc={items[(photoIndex + items.length - 1) % items.length].url}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + items.length - 1) % items.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % items.length)
                    }
                    imageCaption={items[photoIndex].caption}
                    className="font-space-mono text-center"
                />
            }
        </>
    );
}