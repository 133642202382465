import React from "react";

const style = {
        display: "inline",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%"
}

export default function NewFooter({disableOpacity, stickToBottom}) {
    let getStyle = () => {
        if (stickToBottom) {
            return style
        }
        return null
    }
    return (
        <footer>
            <div className={`bg-black ${disableOpacity ? "" : "bg-opacity-60"} pb-10 pt-10`} style={getStyle()} >
                <p className="text-white font-space-mono text-center">
                    UNSAFE GORAŽDE PROJECT © Kuma International 2022, All right reserved.
                </p>
            </div>
        </footer>
    );
}