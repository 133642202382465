import React, { useRef } from "react";
import Navbar from "../components/Navbar";
import NewFooter from "../components/NewFooter";
import emailjs from '@emailjs/browser';

export default function Contact() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault()
        emailjs.sendForm('service_foqvksq', 'template_2f74a0j', form.current, 'KTG1XDwgxkaSl2XGr')
            .then((result) => {
                alert("Email succesfully sent.")
            }, (error) => {
                alert("Error, please try again later.")
            });
    }
    return (
        <>
            <div className="main">
                <Navbar></Navbar>
                <div className="pb-1 sm:p-10">
                    <div className="bg-black bg-opacity-70 p-10 sm:p-20 m-5 sm:m-20 md:m-40 text-center">
                        <div className="text-white font-space-mono text-xl sm:text-3xl mb-10 text-left font-bold">CONTACT US</div>
                        <p className="text-white font-space-mono mb-10 text-left">
                        Don’t hesitate to get in touch to learn more about the Goražde project or if you have questions about future screenings and would like to invite the movie to your favorite cinema. 
                        <br/> <br/>
                        Do you want to share your story from Goražde with us? Please send us a message. We will be happy to read you and incorporate your testimony into our web project.
                        Thank you!
                        </p>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="flex flex-col md:flex-row justify-center items-center">
                                <div className="flex flex-col w-full md:w-1/2 md:mr-10">
                                    <div className="p-5 text-left">
                                        <label className="text-white font-space-mono font-bold">YOUR NAME</label><br />
                                        <input className="w-full  appearance-none required:border-red-500 p-2 text-white font-space-mono font-bold placeholder-white" type="text" id="first-name" style={{ background: "#D9D9D9", textShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)" }} placeholder="YOUR NAME" name="name"></input>
                                    </div>
                                    <div className="p-5 text-left">
                                        <label className="text-white font-space-mono font-bold">YOUR EMAIL</label><br />
                                        <input className="w-full appearance-none required:border-red-500 p-2 text-white font-space-mono font-bold placeholder-white" type="text" id="first-name" style={{ background: "#D9D9D9", textShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)" }} placeholder="YOUR E-MAIL" name="email"></input>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2 p-5 text-left">
                                    <label className="text-white font-space-mono font-bold">MESSAGE</label><br />
                                    <textarea className="inline-block appearance-none required:border-red-500 p-2 text-white font-space-mono w-full h-64 font-bold placeholder-white"
                                        type="text"
                                        id="message"
                                        style={{ background: "#D9D9D9", textShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)" }} placeholder="MESSAGE" name="message"></textarea>
                                </div>
                            </div>
                            <input value="SEND" type="submit" className="mx-auto p-3 w-32 text-center font-space-mono text-white font-bold" style={{ background: "#D9D9D9" }}>
                            </input>
                        </form>
                    </div>
                </div>
                <NewFooter />
            </div>
        </>
    );
}