import './App.css';
import Home from './pages/Home';
import { Routes, Route, Link } from "react-router-dom";
import Documentary from './pages/Documentary';
import Book from './pages/Book';
import Gallery from './pages/Gallery';
import Blog from './pages/Blog';
import About from './pages/About';
import PostDetails from './pages/PostDetails';
import Contact from './pages/Contact';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/documentary" element={<Documentary />} />
      <Route path="/book" element={<Book />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/about" element={<About />} />
      <Route path="/post/:id" element={ <PostDetails/> }/>
      <Route path="/contact" element={ <Contact/> }/>
    </Routes>
  );
}

export default App;
