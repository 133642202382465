import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import blogItems from "./BlogStore";
import NewFooter from "../components/NewFooter";

export default function PostDetails() {
    let { id } = useParams();
    let item = blogItems.find((el) => el.id == id )
    return (
        <>
            <div className="main">
                <Navbar></Navbar>
                <div className="pb-1 sm:p-10 ">
                    <div className="bg-black bg-opacity-70 p-10 sm:p-20 m-5 sm:m-10">
                        <div className="md:border-2 md:border-white md:border-solid md:p-10 mt-8 mb-8">
                            <div className="text-white font-space-mono text-xl sm:text-3xl mb-10 text-center">{item.title}</div>
                            <p className="text-white font-space-mono text-justify">
                                {item.text}
                            </p>
                        </div>
                    </div>
                </div>
                <NewFooter />
            </div>
        </>
    );
}