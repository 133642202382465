import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import NewFooter from "../components/NewFooter";

export default function About() {
    return (
        <>
            <div className="main">
                <Navbar></Navbar>
                <div className="pb-1 sm:p-10 ">
                    <div className="bg-black bg-opacity-70 p-10 sm:p-20 m-5 sm:m-20 md:m-40">
                        <div className="text-white font-space-mono text-xl sm:text-3xl mb-10 text-center">ABOUT THE PROJECT</div>
                        <p className="text-white font-space-mono text-justify">
                            UNSAFE GORAŽDE is a multimedia project promoted by Kuma International in 2018 together with Enrico Dagnino, Claudia Zini, Fuad Fule Bavčić and Quentin Fagart with the aim to collect war memories and testimonies, explore archives and interview war veterans and different members of the civil society who bravely defended their city from aggression. The project focuses on the city of Goražde and its inhabitants who survived the Bosnian war defending their homes against all odds, while the other cities along the Drina river fell to the enemy.
                            <br/><br/>The story’s main protagonist is Fuad Fule Bavčić, a retired economist and war veteran who spent the war years in the besieged city before joining his family in Sarajevo in 1996. What emerges from the film is a collective story of courage that ultimately speaks of love for family, city, and country.
                            <br/><br/>UNSAFE GORAŽDE was developed across different media. Besides a documentary film, a book and a photo exhibition premiered in Goražde in May 2022 to mark 30 years since the beginning of the siege. The project was realized by Kuma International in partnership with Brodac Gallery and funded by the International Relief Fund for Organisations in Culture and Education 2021 of the German Federal Foreign Office, the Goethe-Institut and other partners.
                        </p>
                    </div>
                </div>
                <NewFooter />
            </div>
        </>
    );
}