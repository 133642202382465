import React from "react";

export default function InterviewItem({image, title, onClick}) {
    return (
        <>
        <div className="flex flex-col text-center">
        <img src={image} className="object-cover h-64 w-64" alt="Interview image." onClick={onClick}></img>
        <p className="text-black font-space-mono mt-3 mb-3">{title}</p>
        </div>
        </>
    );
}