import React from "react";
import { Link } from "react-router-dom";

export default function BlogItem({id, title, text}) {
    return (
        <>
        <Link to={`/post/${id}`}>
        <div className="border-2 border-solid border-white p-10 mt-8 mb-8">
            <p className="text-left text-white font-space-mono text-3xl mb-4">
                {title}
            </p>
            <p className=" text-white font-space-mono ml-4">
                {text}
            </p>
        </div>
        </Link>
        </>
    );
}