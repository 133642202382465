import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Logo from "../assets/unsafe-gorazde-logo.png"
import InterviewItem from "../components/InterviewItem";
import Footer from "../components/Footer";
import Typewriter from 'typewriter-effect';
import { Link } from "react-router-dom";
import NewFooter from "../components/NewFooter";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


const people = [
    {
        name: "Goražde - Hero City",
        img: "gorazde.jpg"
    },
    {
        name: "Adnan & Elma Bučo",
        img: "adnanielma.jpg"
    },
    {
        name: "Safet Žuga",
        img: "safet.jpg"
    },
    {
        name: "Ahmet Sejdić",
        img: "ahmet.jpg"
    },
    {
        name: "Alen Muhić",
        img: "alen.jpg"
    },
    {
        name: "Hasan Gabela",
        img: "hasan.jpg"
    },
    {
        name: "Slavko Kisura",
        img: "slavko.jpg"
    },
    {
        name: "Selim Imamović",
        img: "selim.jpg"
    },
    {
        name: "Mirsad \"Romi\" Karović",
        img: "romi.jpg"
    },
    {
        name: "Fuad \"Fule\" Bavčić",
        img: "fule.jpg"
    },
    {
        name: "Aziz \"Zisko\" Gluščević",
        img: "zisko.jpg"
    },
    {
        name: "Fuad & Merita Bavčić",
        img: "fuadimerita.jpg"
    },
    {
        name: "Abduselam \"Pelam\" Sijerčić",
        img: "pelam.jpg"
    },
    {
        name: "Amra Muftić",
        img: "amra.jpg"
    },
    {
        name: "Suad \"Žuti\" Ćamo",
        img: "zuti.jpg"
    },
    {
        name: "Srećko Đikić",
        img: "srecko.jpg"
    },
    {
        name: "Amra & Namir Muftić",
        img: "amrainamir.jpg"
    },
    {
        name: "Muhamed Sijerčić",
        img: "muhamed.jpg"
    },
    {
        name: "Emir & Mirjana Avdić",
        img: "emirimirjana.jpg"
    },
    {
        name: "Safet Bučo",
        img: "safetbuco.jpg"
    },

];

export default function Home() {
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <div className="main pb-20">
                <Navbar>
                </Navbar>
                <div className="h-screen">
                <img src={Logo}
                    className="mx-auto mt-20  sm:h-50 sm:w-50"
                    alt="Logo for unsafe gorazde.">
                </img>
                <div className="text-center mt-10 mr-5 sm:mr-20  ml-5 sm:ml-20">
                    <p className="text-white font-space-mono text-xl sm:text-2xl drop-shadow-lg font-bold text-center">
                        <Typewriter options={{delay: 75}}
                            onInit={(typewriter) => {
                                typewriter.typeString('This is a documentary film on the city of Goražde and its inhabitants who survived the Bosnian war defending their homes against all odds, while the other cities along the Drina river fell to the enemy.')
                                    .callFunction(() => {
                                        console.log('String typed out!');
                                    })
                                    .start();
                            }}
                        />
                    </p>
                </div>
                <Link to="/documentary">
                <div className="font-space-mono mx-auto text-center h-15 w-80 mt-10 bg-black bg-opacity-60 text-white p-3 text-2xl">
                    WATCH THE DOCUMENTARY
                </div>
                </Link>
                </div>
                {/* <div className="font-space-mono mx-auto text-center mt-10 text-white p-3 text-2xl">
                    or whole interviews down here
                </div>
                <div className="font-space-mono mx-auto text-center mt-20 mb-20 text-white p-3 text-3xl">
                    SCREENINGS AND AWARDS
                </div>
                <div className="flex flex-col sm:m-8 md:flex-row justify-around gap-8 justify-items-center">
                    <div className="text-center h-56 w-56 bg-black bg-opacity-60 self-center">Jedan</div>
                    <div className="text-center h-56 w-56 bg-black bg-opacity-60 self-center">Dva</div>
                    <div className="text-center h-56 w-56 bg-black bg-opacity-60 self-center">Tri</div>
                </div> */}

            </div>
            <div className="section-interviews">
                <div className="text-center mt-10 font-space-mono text-black text-3xl">
                    PROTAGONISTS
                </div>
                <div className="p-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-8 marker: justify-items-center"
                style={{  gridAutoRows: "minmax(min-content, max-content)"}}>
                    {
                        people.map((item, index) => {
                            return <InterviewItem
                                title={item.name}
                                image={`/home/${item.img}`}
                                onClick={() => {
                                    setPhotoIndex(index);
                                    setIsOpen(true);
                                    console.log("Jedan")
                                }}
                            ></InterviewItem>
                        })
                    }
                </div>
                {isOpen &&
                <Lightbox
                    mainSrc={`/home/${people[photoIndex].img}`}
                    nextSrc={`home/${(people[(photoIndex + 1) % people.length]).img}`}
                    prevSrc={`home/${(people[(photoIndex + people.length - 1) % people.length]).img}`}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + people.length - 1) % people.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % people.length)
                    }
                />
            }
            </div>
            <NewFooter disableOpacity={true}/>
        </>
    );
}