import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import NewFooter from "../components/NewFooter";
import BookImage from "../assets/book.webp"

export default function Book() {
    return (
        <>
            <div className="main">
                <Navbar></Navbar>
                <div className="pb-1 sm:p-10">
                    <div className="bg-black bg-opacity-70 p-10 sm:p-20 m-5 sm:m-20 md:m-40">
                        <div className="text-white font-space-mono text-xl sm:text-3xl mb-10 text-center">UNSAFE GORAŽDE BOOK</div>
                        <p className="text-white font-space-mono text-justify">
                            UNSAFE GORAŽDE illustrates the story of the city of Goražde and its inhabitants who survived the Bosnian war defending their homes against all odds, while the other cities along the Drina river fell to the enemy. Goražde is called a hero city, yet it has vanished from the public eye, hidden away in the east, forgotten by the Bosnian government. Like many other cities in the country, people are leaving to seek a better future elsewhere.
                            <br/><br/>Still, the people of Goražde you will meet in the book, portrayed by photographers Enrico Dagnino and Quentin Fagart, tell a story of heroes and heroic times when they found strength they didn’t know they had to defend their closest ones, their homes. They don’t perceive themselves as victims. They stand proud in front of their houses and talk every day to their children about what happened during the war so that it may never happen again.
                            <br/><br/> The book is part of the UNSAFE GORAŽDE multimedia project initiated by Kuma International together with Enrico Dagnino, Claudia Zini, Fuad Fule Bavčić and Quentin Fagart with the aim to collect war memories and testimonies, explore archives and interview war veterans and different members of the civil society who bravely defended their city from aggression. It is dedicated to those who lost their lives in wars and those still affected by war.
                        </p>
                        <img src={BookImage} className="mt-5" alt="Image of unsafe gorazde book"></img>
                        <div className="text-center mt-5">
                            <a href="mailto:info@kumainternational.org">
                            <button className="font-space-mono p-5 mx-auto text-white" style={{ backgroundColor: "#C4C4C4" }}>
                                GET YOUR COPY HERE
                            </button>
                            </a>
                        </div>
                    </div>
                </div>
                <NewFooter/>
            </div>
        </>
    );
}